import React from "react";
import { ActionButton } from "../_shared";
import { Card, FlexBetweenCenterContainer, FlexCenterCenterContainer, MediumSpacer } from "../_shared/layout";
import { BoldContentText, SmallText } from "../_shared/typography";
import { preexistingPaidCustomers, S3Key } from "../../utils";
import { useSelectFromRedux } from "../../utils/_hooks";

export const Billing = () => {
    const { userProfile } = useSelectFromRedux((state) => state.cuser);
    const isPreexistingPayer = preexistingPaidCustomers.includes(userProfile.email);

    return (
        <div style={{ width: "100%" }}>
            <Card>
                <FlexBetweenCenterContainer>
                    <BoldContentText>Standard</BoldContentText>
                    <BoldContentText>{isPreexistingPayer ? "1,000 KES" : "1,500 KES"}</BoldContentText>
                </FlexBetweenCenterContainer>
                <FlexBetweenCenterContainer>
                    <SmallText>for 1 month</SmallText>
                    <SmallText></SmallText>
                    {/* <SmallText>{moment().format("MMM Do, YYYY")}</SmallText> */}
                </FlexBetweenCenterContainer>
                <MediumSpacer />
                <FlexCenterCenterContainer>
                    <a
                        href={isPreexistingPayer ? "https://buy.stripe.com/6oEdRh7tjdijeRidQT" : "https://buy.stripe.com/14k5kLcNDdijdNe8wA"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ActionButton>Subscribe to Standard</ActionButton>
                    </a>
                </FlexCenterCenterContainer>
            </Card>
            <Card>
                <FlexBetweenCenterContainer>
                    <BoldContentText>Premium</BoldContentText>
                    <BoldContentText>2,500 KES</BoldContentText>
                </FlexBetweenCenterContainer>
                <FlexBetweenCenterContainer>
                    <SmallText>for 1 month</SmallText>
                    <SmallText></SmallText>
                    {/* <SmallText>{moment().format("MMM Do, YYYY")}</SmallText> */}
                </FlexBetweenCenterContainer>
                <MediumSpacer />
                <FlexCenterCenterContainer>
                    <a href="https://buy.stripe.com/cN2aF5fZP2DF4cEcMR" target="_blank" rel="noopener noreferrer">
                        <ActionButton>Subscribe to Premium</ActionButton>
                    </a>
                </FlexCenterCenterContainer>
            </Card>
            <Card>
                <FlexBetweenCenterContainer>
                    <BoldContentText>Atlas Alerts</BoldContentText>
                    <BoldContentText>715 KES</BoldContentText>
                </FlexBetweenCenterContainer>
                <FlexBetweenCenterContainer>
                    <SmallText>for 1 month</SmallText>
                    <SmallText></SmallText>
                    {/* <SmallText>{moment().format("MMM Do, YYYY")}</SmallText> */}
                </FlexBetweenCenterContainer>
                <MediumSpacer />
                <FlexCenterCenterContainer>
                    <a href="https://buy.stripe.com/5kAcNdaFv0vx38A28e" target="_blank" rel="noopener noreferrer">
                        <ActionButton>Subscribe to Atlas Alerts</ActionButton>
                    </a>
                </FlexCenterCenterContainer>
            </Card>
        </div>
    );
};
